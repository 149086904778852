<template>
  <div>
    <span class="pr-1">
      <fa-icon v-if="!running" class="stop-watch__fa-icon teal--text" size="lg" :icon="['fad', 'play-circle']" @click="start()" />
      <fa-icon v-if="running" class="stop-watch__fa-icon teal--text" size="lg" :icon="['fad', 'stop-circle']" @click="stop()" />
    </span>
    {{ clock }}
    <span class="pl-1">
      <fa-icon class="stop-watch__fa-icon teal--text" size="lg" :icon="['fad', 'times-circle']" @click="reset()" />
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StopWatch',
  data() {
    return {
      clock: '00:00:00',
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
    }
  },
  computed: {
    ...mapGetters(['sudoku']),
    solved() {
      return this.sudoku.solved
    },
  },
  watch: {
    solved: function (val) {
      if (val) {
        this.stop()
      }
    },
  },
  methods: {
    start() {
      if (this.running || this.solved) return

      if (this.timeBegan === null) {
        this.reset()
        this.timeBegan = new Date()
      }

      if (this.timeStopped !== null) {
        this.stoppedDuration += new Date() - this.timeStopped
      }

      this.started = setInterval(this.clockRunning, 100)
      this.running = true
    },
    stop() {
      this.running = false
      this.timeStopped = new Date()
      clearInterval(this.started)
    },
    reset() {
      if (this.solved) return
      const restart = this.running
      this.running = false
      clearInterval(this.started)
      this.stoppedDuration = 0
      this.timeBegan = null
      this.timeStopped = null
      this.clock = '00:00:00'
      if (restart) this.start()
    },
    clockRunning() {
      let currentTime = new Date()
      let timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration),
        hour = timeElapsed.getUTCHours(),
        min = timeElapsed.getUTCMinutes(),
        sec = timeElapsed.getUTCSeconds()
      if (hour > 99) {
        this.reset()
        return
      }
      this.clock = this.zeroPrefix(hour, 2) + ':' + this.zeroPrefix(min, 2) + ':' + this.zeroPrefix(sec, 2)
    },
    zeroPrefix(num, digit) {
      let zero = ''
      for (let i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    },
  },
}
</script>

<style lang="scss">
.stop-watch {
  &__fa-icon {
    cursor: pointer;
  }
}
</style>
