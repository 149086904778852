<template>
  <div>
    <strong>{{ checked }}</strong>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Check',
  computed: {
    ...mapGetters(['sudoku']),
    checked() {
      if (this.sudoku.solved && this.sudoku.checked === 1) return this.$t('components.sudoku.check.perfectly_solved')
      return this.$t('components.sudoku.check.solved', { value: String(this.sudoku.checked) })
    },
  },
}
</script>

<style lang="scss"></style>
