<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-progress-linear
          class="progress__bar"
          :value="progress"
          v-bind="attrs"
          :aria-label="$t('components.sudoku.progress.label')"
          color="blue-grey"
          height="20"
          v-on="on"
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </template>
      <div>
        <i18n path="components.sudoku.progress.div_progress">
          <template #progress>
            {{ progress }}
          </template>
        </i18n>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Progress',
  computed: {
    ...mapGetters(['sudoku', 'progress']),
  },
}
</script>

<style lang="scss">
.progress {
  &__bar {
    width: 90px;
  }
}
</style>
