<template>
  <div>
    <div class="sudoku-field"><slot name="buttons"></slot></div>
    <table class="mx-auto">
      <tbody>
        <tr v-for="row in 9" :key="row">
          <td
            v-for="column in 9"
            :id="`sudoku-field-cell-${getCellId(row, column) + 1}`"
            :key="column"
            :class="[getSubgridClass(row, column), getSubgridBorderClass(row, column)]"
            class="element text-center"
          >
            <div
              v-if="cellHasPuzzleDigit(row, column) > 0"
              class="sudoku-field__fixed"
              :class="[highlightMode ? classHighlighted(row, column) : null]"
            >
              {{ getPuzzleDigit(row, column) }}
            </div>
            <div v-else-if="cellHasEntry(row, column) > 0" :class="[getBackgroundClass(row, column)]">
              <a class="sudoku-field__entry" :class="getFontClass(row, column)" href="#" @click.prevent="activateCell(row, column)">{{
                sudoku.entries[getCellId(row, column)]
              }}</a>
            </div>
            <div
              v-else
              :class="[
                cellIsActive(row, column) ? $theme.sudoku.elements.active.backgroundColor : '',
                highlightMode ? classHighlighted(row, column) : null,
                'cursor-pointer',
              ]"
              @click="activateElement(getCellId(row, column))"
            >
              <div
                v-if="typeof sudoku.notes[getCellId(row, column)] !== 'undefined' && sudoku.notes[getCellId(row, column)] !== null"
                :class="[highlightMode ? classHighlighted(row, column) : null]"
              >
                <v-row v-for="noteRow in 3" :key="`noteRow${noteRow}`" class="ma-0 note--row">
                  <v-col
                    v-for="noteColumn in 3"
                    :key="`noteColumn${noteColumn}`"
                    :class="[getNoteNumberColor(getCellId(row, column), getNoteNumber(noteRow, noteColumn)), 'note pa-0']"
                    @click="toggleNote(getCellId(row, column), getNoteNumber(noteRow, noteColumn))"
                  >
                    {{ getNoteNumber(noteRow, noteColumn) }}
                  </v-col>
                </v-row>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { sudoku, theme } from '@/mixins'
import { mapGetters } from 'vuex'
import { isBrowser, isTablet } from 'mobile-device-detect'

export default {
  name: 'SudokuField',
  mixins: [sudoku, theme],
  inject: ['theme'],
  computed: {
    ...mapGetters(['getActiveCellId', 'getHighlightMode', 'getSpeedMode', 'getInvertMode']),
    isSolved() {
      return this.sudoku.solved
    },
    highlightMode() {
      return this.getHighlightMode
    },
    invertMode() {
      return this.getInvertMode
    },
    speedMode() {
      return this.getSpeedMode
    },
  },
  methods: {
    activateCell(row, column) {
      const cellId = this.getCellId(row, column)
      if (!this.isSolved) {
        if (cellId !== this.getActiveCellId && !this.cellHasWarning(row, column)) {
          this.$store.dispatch('setActiveCellId', cellId)
        } else {
          this.deleteEntry(row, column)
        }
      }
    },
    cellHasPuzzleDigit(row, column) {
      const digit = this.getPuzzleDigit(row, column)
      return /^[1-9]$/.test(digit)
    },
    cellHasEntry(row, column) {
      const digit = this.sudoku.entries[this.getCellId(row, column)]
      return /^[1-9]$/.test(digit)
    },
    cellHasWarning(row, column) {
      return !!this.sudoku.warnings[this.getCellId(row, column)]
    },
    cellIsActive(row, column) {
      return this.getCellId(row, column) === this.sudoku.activeCellId
    },
    cellIsHighlighted(row, column) {
      const fieldNum = (row - 1) * 9 + (column - 1)
      return this.highlightedFields.includes(fieldNum)
    },
    classHighlighted(row, column) {
      return this.cellIsHighlighted(row, column) ? this.$theme.sudoku.elements.highlight.backgroundColor : ''
    },
    deleteEntry(row, column) {
      this.$store.dispatch('setEntry', { elementId: this.getCellId(row, column), val: undefined })
      this.$store.dispatch('setWarning', { elementId: this.getCellId(row, column), val: undefined })
      this.activateElement(this.getCellId(row, column))
    },
    getBackgroundClass(row, column) {
      if (this.cellHasWarning(row, column)) {
        return this.$theme.sudoku.elements.active.backgroundWarningColor
      }
      if (this.highlightMode && this.cellIsHighlighted(row, column)) {
        return this.$theme.sudoku.elements.highlight.backgroundColor
      }
      return this.cellIsActive(row, column) ? this.$theme.sudoku.elements.active.backgroundColor : ''
    },
    getFontClass(row, column) {
      if (this.cellHasWarning(row, column)) {
        return this.$theme.sudoku.elements.active.fontWarningColor
      }
      return this.$theme.sudoku.elements.active.fontColor
    },
    getPuzzleDigit(row, column) {
      return this.puzzle[this.getCellId(row, column)]
    },
    getSubgridClass(row, column) {
      if ((row < 4 || row > 6) && column > 3 && column < 7) {
        return 'subgrid-even'
      } else if ((column < 4 || column > 6) && row > 3 && row < 7) {
        return 'subgrid-even'
      } else return 'subgrid-odd'
    },
    getSubgridBorderClass(row, column) {
      const subgridBorderClass = []
      if (row === 3 || row === 6) {
        subgridBorderClass.push('border-bottom')
      }
      if (column === 3 || column === 6) {
        subgridBorderClass.push('border-right')
      }
      return subgridBorderClass.join(' ')
    },
    getCellId(row, column) {
      return 9 * row + column - 10
    },
    activateElement(id) {
      if (!this.isSolved && (typeof this.sudoku.notes[id] === 'undefined' || this.sudoku.notes[id] === null)) {
        this.initNote(id)
      }
      this.$store.dispatch('setEntry', { elementId: id, val: undefined })
      this.$store.dispatch('setActiveCellId', id)
    },
    initNote(elementId) {
      const bin = this.invertMode ? 511 : 0
      this.$store.dispatch('setNote', { elementId: elementId, val: bin })
    },
    getNoteNumber(row, column) {
      return 3 * row + column - 3
    },
    getNoteNumberColor(elementId, number) {
      const bin = Math.pow(2, number - 1)
      const note = this.sudoku.notes[elementId]
      if ((bin & note) === bin) {
        return this.$theme.sudoku.notes.color.active
      }
      return this.$theme.sudoku.notes.color.inactive
    },
    toggleNote(cellId, noteNumber) {
      const speedMode = this.$vuetify.breakpoint.smAndUp && this.speedMode && (isTablet || isBrowser)
      if (speedMode) {
        setTimeout(() => {
          if (!this.isSolved && (isBrowser || isTablet)) this.toggleNoteByDigit(cellId, noteNumber)
        }, 1)
      } else {
        if (!this.isSolved && (isBrowser || isTablet)) this.toggleNoteByDigit(cellId, noteNumber)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';

.sudoku-field {
  position: absolute;
  top: -40px;
  width: 100%;
  &__entry {
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
  }
  &__fixed {
    user-select: none;
    -webkit-user-select: none;
  }
}
table {
  border-collapse: collapse;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0;
}
.playground-sudoku-field-candidate {
  font-size: 0.75rem;
}
.playground-sudoku-field-number {
  font-size: 1.25rem;
}

.element > div {
  font-size: 1.1rem;
  font-weight: 600;
  height: 27px;
  width: 27px;
}
.element .note {
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  user-select: none;
  -webkit-user-select: none;
}

.note--row {
  height: 9px;
}

@media (min-width: 332px) {
  .element > div {
    font-size: 1.2rem;
    height: 30px;
    width: 30px;
  }
  .note--row {
    height: 10px;
  }
}
@media (min-width: 359px) {
  .element > div {
    font-size: 1.35rem;
    height: 33px;
    width: 33px;
  }
  .note--row {
    height: 11px;
  }
}
@media (min-width: 386px) {
  .element > div {
    font-size: 1.45rem;
    height: 36px;
    width: 36px;
  }
  .note--row {
    height: 12px;
  }
}
@media (min-width: 413px) {
  .element > div {
    font-size: 1.6rem;
    height: 39px;
    width: 39px;
  }
  .note--row {
    height: 13px;
  }
}
@media (min-width: 440px) {
  .element > div {
    line-height: 1.35;
    font-size: 1.8rem;
    height: 42px;
    width: 42px;
  }
  .element .note {
    font-size: 12px;
    line-height: 13px;
  }
  .note--row {
    height: 14px;
  }
}

#app.theme--dark {
  .subgrid-even {
    background-color: map-get($grey, 'darken-4');
  }
  .subgrid-odd {
    background-color: map-get($shades, 'black');
  }
  .element {
    border: 1px solid map-get($grey, 'darken-1');
  }
  .border-bottom {
    border-bottom: 3px solid map-get($grey, 'darken-1');
  }
  .border-right {
    border-right: 3px solid map-get($grey, 'darken-1');
  }
}
#app.theme--light {
  .subgrid-even {
    background-color: map-get($grey, 'lighten-5');
  }
  .subgrid-odd {
    background-color: map-get($shades, 'white');
  }
  .element {
    border: 1px solid map-get($grey, 'darken-4');
  }
  .border-bottom {
    border-bottom: 3px solid map-get($grey, 'darken-4');
  }
  .border-right {
    border-right: 3px solid map-get($grey, 'darken-4');
  }
}
</style>
