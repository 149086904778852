<template>
  <div class="text-center py-3">
    <span v-for="digit in 9" :key="digit">
      <v-btn
        :id="getButtonId(digit)"
        :class="['px-0', digit < 9 ? 'mr-1 mr-sm-2' : '']"
        :color="$theme.sudoku.buttons.color"
        min-height="27px"
        min-width="27px"
        :disabled="(sudoku.activeCellId === null || getEntryOfCell()) && digit !== sudoku.entries[sudoku.activeCellId]"
        @click="toggleNoteByDigit(sudoku.activeCellId, digit)"
      >
        <span :class="['sudoku-buttons-btn-number', digit === sudoku.entries[sudoku.activeCellId] ? 'sudoku-buttons-btn-has-entry' : '']">
          {{ digit }}
        </span>
        <fa-icon
          v-if="btnHasCheckInFlag(digit) && getEntryOfCell() === false"
          :icon="['fal', 'expand']"
          class="green--text sudoku-buttons-btn-fa-expand"
        />
      </v-btn>
    </span>
  </div>
</template>

<script>
import { sudoku, theme } from '@/mixins'

export default {
  name: 'SudokuButtons',
  mixins: [sudoku, theme],
}
</script>

<style lang="scss">
[id^='sudoku-buttons-btn-'] {
  height: calc((100vw - 74px) / 9) !important;
  width: calc((100vw - 74px) / 9);
}
.sudoku-buttons-btn-number,
.sudoku-buttons-btn-fa-expand {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sudoku-buttons-btn-number {
  font-size: 0.85rem;
}
.sudoku-buttons-btn-fa-expand {
  font-size: 1.25rem;
  transform: translate(-50%, -50%);
  animation: animateFaExpand 1.25s ease-in-out infinite;
}
.sudoku-buttons-btn-has-entry {
  font-size: 1.5rem;
  font-weight: 600;
}

@keyframes animateFaExpand {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

@media (min-width: 440px) {
  [id^='sudoku-buttons-btn-'] {
    height: 41px !important;
    width: 41px;
  }
  [id^='sudoku-buttons-btn-'] > span {
    font-size: 0.85rem;
    transition: all 1s;
  }
}
</style>
