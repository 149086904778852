<template>
  <div id="component-sudoku-container">
    <div id="component-sudoku-card" class="mx-auto show-field">
      <sudoku-field id="component-sudoku-field">
        <template #buttons>
          <v-row no-gutters class="mx-1 text-center">
            <v-col class="flex-grow-1">
              <v-btn
                :disabled="disableSavingButton || (isAuthenticated && !saving.counter)"
                :aria-label="$t('components.sudoku.btn_save')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_save')"
                @click.prevent="saveSudoku()"
              >
                <div v-if="saving.active">
                  <fa-icon spin :icon="['fal', 'spinner-third']" transform="grow-7 rotate-270" />
                </div>
                <div v-else>
                  <fa-icon :icon="['fal', 'save']" transform="grow-16" />
                </div>
              </v-btn>
            </v-col>
            <v-col class="flex-grow-1"
              ><v-btn
                ref="printButton"
                :aria-label="$t('components.sudoku.btn_print')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_print')"
                @click.prevent="print()"
              >
                <fa-icon :icon="['fal', 'print']" transform="grow-16" /> </v-btn
            ></v-col>
            <v-col class="flex-grow-1"
              ><v-btn
                :aria-label="$t('components.sudoku.btn_reset')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_reset')"
                @click.prevent="resetSudoku()"
              >
                <fa-icon :icon="['fal', 'vacuum']" transform="grow-16" /> </v-btn
            ></v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" class="flex-grow-1"
              ><v-btn
                :aria-label="$t('components.sudoku.btn_speed_mode')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_speed_mode')"
                @click.prevent="toggleSpeedMode()"
              >
                <fa-icon v-if="speedMode" :icon="['fal', 'rabbit-fast']" transform="grow-16" />
                <fa-icon v-else :icon="['fal', 'rabbit']" transform="grow-16" /> </v-btn
            ></v-col>
            <v-col class="flex-grow-1"
              ><v-btn
                ref="toggleHighlightModeButton"
                :aria-label="$t('components.sudoku.btn_highlight_mode')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_highlight_mode')"
                @click.prevent="toggleHighlightMode()"
              >
                <fa-icon v-if="highlightMode" :icon="['fad', 'highlighter-line']" transform="grow-14" />
                <fa-icon v-else :icon="['fal', 'highlighter']" transform="grow-14" /> </v-btn
            ></v-col>
            <v-col class="flex-grow-1"
              ><v-btn
                ref="magicNotesButton"
                :disabled="inverterDisabled"
                :aria-label="$t('components.sudoku.btn_magic_notes')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_magic_notes')"
                @click.prevent="magicNotes()"
              >
                <fa-icon :icon="['far', 'wand-sparkles']" transform="grow-14 flip-h" /> </v-btn
            ></v-col>
            <v-col class="flex-grow-1"
              ><v-btn
                ref="invertCandidatesButton"
                :disabled="inverterDisabled"
                :aria-label="$t('components.sudoku.btn_invert')"
                class="d-print-none"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_invert')"
                @click.prevent="invertCandidates()"
              >
                <fa-icon :icon="['fal', 'exchange']" transform="grow-16" /> </v-btn
            ></v-col>
            <v-col class="flex-grow-1"
              ><v-btn
                ref="toggleInvertModeButton"
                class="d-print-none"
                :aria-label="$t('components.sudoku.btn_invert_mode')"
                fab
                text
                x-small
                exact
                :title="$t('components.sudoku.btn_invert_mode')"
                @click.prevent="toggleInvertMode()"
              >
                <fa-layers v-if="invertMode">
                  <fa-icon :icon="['fas', 'sort-up']" transform="rotate-90 grow-22" />
                  <fa-icon :icon="['fal', 'sort-down']" transform="rotate-90 grow-16" />
                </fa-layers>
                <fa-layers v-else>
                  <fa-icon :icon="['fas', 'sort-up']" transform="rotate-270 grow-22" />
                  <fa-icon :icon="['fal', 'sort-down']" transform="rotate-270 grow-16" />
                </fa-layers> </v-btn
            ></v-col>
          </v-row>
        </template>
      </sudoku-field>
    </div>
    <p class="d-none d-print-block text-center text-caption">
      © {{ $t('globals.title') }} - {{ dailySudoku ? 'Sudoku des Tages' : getLevel(sudoku.stars) }}. URL: {{ currentUrl }} [{{
        currentDate
      }}]
    </p>
    <div class="d-print-none">
      <div class="sudoku__div--progress mx-auto">
        <v-row class="my-0">
          <v-col cols="6" sm="4" class="text-left pt-10-px pb-0 pl-4">
            <Progress />
          </v-col>
          <v-col cols="6" sm="4" class="pt-2 pb-0 text-right text-sm-center">
            <stop-watch />
          </v-col>
          <v-col sm="4" class="d-none d-sm-block pt-2 pb-0 pr-4 text-right">
            <check />
          </v-col>
        </v-row>
      </div>
      <SudokuButtons />
      <div v-if="$vuetify.breakpoint.xsOnly" class="text-center">
        <check />
      </div>
    </div>
  </div>
</template>

<script>
import Check from '@/components/sudoku/Check.vue'
import Progress from '@/components/sudoku/Progress.vue'
import StopWatch from '@/components/sudoku/StopWatch.vue'
import SudokuButtons from '@/components/sudoku/Buttons.vue'
import SudokuField from '@/components/sudoku/Field.vue'
import { Com } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { theme, sudoku } from '@/mixins'
import { isBrowser, isTablet } from 'mobile-device-detect'

export default {
  name: 'Sudoku',
  components: {
    Check,
    Progress,
    StopWatch,
    SudokuButtons,
    SudokuField,
  },
  mixins: [theme, sudoku],
  props: {
    dailySudoku: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableSavingButton: false,
    }
  },
  computed: {
    ...mapGetters(['sudoku', 'user', 'saving', 'progress', 'getHighlightMode', 'getSpeedMode', 'getInvertMode']),
    inverterDisabled() {
      return this.sudoku.activeCellId === null || ![undefined, null].includes(this.sudoku.entries[this.sudoku.activeCellId])
    },
    currentDate() {
      return new Date().toISOString().slice(0, 10)
    },
    currentUrl() {
      if (this.dailySudoku) return location.href
      const hostname = location.href.split('/')[2]
      return `${location.protocol}//${hostname}${this.$t('hyperlinks.id.href', { number: this.sudoku.id })}`
    },
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    speedMode() {
      return this.getSpeedMode
    },
    highlightMode() {
      return this.getHighlightMode
    },
    speedModeShow() {
      return this.$vuetify.breakpoint.smAndUp && (isBrowser || isTablet)
    },
    invertMode() {
      return this.getInvertMode
    },
  },
  beforeMount() {
    if (!this.$cookies.isKey('speedMode')) {
      this.$cookies.set('speedMode', isBrowser, '2y')
    }
    const highlightMode = this.$cookies.get('highlightMode') === 'true'
    this.setHighlightMode(highlightMode)
    const invertMode = this.$cookies.get('invertMode') === 'true'
    this.setInvertMode(invertMode)
    const speedMode = this.$cookies.get('speedMode') === 'true'
    this.setSpeedMode(speedMode)
  },
  methods: {
    ...mapActions([
      'hideLoader',
      'initSudoku',
      'setHighlightMode',
      'setInvertMode',
      'setPurpose',
      'setSavingActive',
      'setSpeedMode',
      'showLoader',
    ]),
    async toggleSpeedMode() {
      const message = !this.speedMode
        ? this.$t('components.sudoku.speed_mode.confirm.message_on')
        : this.$t('components.sudoku.speed_mode.confirm.message_off')
      const res = await this.$confirm(String(message), {
        title: String(this.$t('components.sudoku.speed_mode.confirm.title')),
        color: 'blue-grey lighten-2',
        buttonTrueText: String(this.$t('components.sudoku.speed_mode.confirm.ok')),
        buttonFalseText: String(this.$t('components.puzzles.actions.confirm.cancel')),
      })
      if (res) {
        const speedMode = !this.speedMode
        this.setSpeedMode(speedMode)
        this.$cookies.set('speedMode', speedMode, '2y')
      }
    },
    toggleHighlightMode() {
      const highlightMode = !this.highlightMode
      this.setHighlightMode(highlightMode)
      this.$cookies.set('highlightMode', highlightMode, '2y')
      this.$refs.toggleHighlightModeButton.$el.blur()
    },
    invertCandidates() {
      this.$store.dispatch('setNote', {
        elementId: this.sudoku.activeCellId,
        val: this.mirror_bits(this.sudoku.notes[this.sudoku.activeCellId]),
      })
      this.$refs.invertCandidatesButton.$el.blur()
    },
    magicNotes() {
      const bin = this.calculateDigitSum()
      this.$store.dispatch('setNote', {
        elementId: this.sudoku.activeCellId,
        val: bin,
      })
      this.$refs.magicNotesButton.$el.blur()
    },
    calculateDigitSum() {
      const usedDigits = new Set() // Verwenden eines Sets, um doppelte Werte zu vermeiden

      this.highlightedFields.forEach((index) => {
        if (/^[1-9]$/.test(this.puzzle[index])) {
          usedDigits.add(parseInt(this.puzzle[index], 10))
        }

        if (typeof this.sudoku.entries[index] === 'number') {
          usedDigits.add(this.sudoku.entries[index])
        }
      })

      let sum = 0
      usedDigits.forEach((digit) => {
        // Da die Ziffer 1 dem Wert 2^0 entspricht, subtrahieren wir 1 von der Ziffer, um den korrekten Exponenten zu erhalten
        sum += Math.pow(2, digit - 1)
      })
      return sum
    },
    toggleInvertMode() {
      const invertMode = !this.invertMode
      this.setInvertMode(invertMode)
      this.$cookies.set('invertMode', invertMode, '2y')
      this.$refs.toggleInvertModeButton.$el.blur()
    },
    /**
     * @function mirror_bits
     * @param {number} notesToBinary
     */
    mirror_bits(notesToBinary) {
      return parseInt(
        notesToBinary
          .toString(2)
          .padStart(9, '0')
          .split('')
          .map((n) => {
            return Math.abs(parseInt(n) - 1)
          })
          .join(''),
        2
      )
    },
    getLevel(level) {
      return level === null ? '' : this.$t(`globals.level.level_${level}`)
    },
    async saveSudoku() {
      if (!this.isAuthenticated) {
        await this.$confirm(String(this.$t('components.sudoku.confirm.message')), {
          title: String(this.$t('components.sudoku.confirm.title')),
          color: 'blue-grey lighten-2',
          icon: String(this.$vuetify.icons.values.info),
          buttonTrueText: String(this.$t('components.sudoku.confirm.button')),
          buttonFalseText: '',
        })
        return
      }
      this.disableSavingButton = true
      const com = new Com()
      if (this.isAuthenticated && this.saving.counter) {
        this.setSavingActive(true)
        const savegame = { ...this.sudoku }
        setTimeout(async () => {
          delete savegame.remainingSeconds
          delete savegame.activeCellId
          if (this.dailySudoku) {
            const response = await com.updateDailySudokuSavegame(JSON.stringify(savegame), this.progress)
            this.setSavingActive(false)
            if (typeof response === 'object') await this.$store.dispatch('resetSavingCounter')
          } else {
            const response = await com.updateSavegame(savegame.id, JSON.stringify(savegame), this.progress)
            this.setSavingActive(false)
            if (typeof response === 'object') await this.$store.dispatch('resetSavingCounter')
          }
          this.disableSavingButton = false
        }, 500)
      }
    },
    print() {
      const self = this
      this.setPurpose(this.$t('components.app.navigation.list_item_print_aria_label'))
      this.$refs.printButton.$el.blur()
      this.showLoader()
      setTimeout(() => {
        window.print()
        self.hideLoader({})
      }, 1)
    },
    async resetSudoku() {
      const res = await this.$confirm(String(this.$t('components.sudoku.confirm_reset.message')), {
        title: String(this.$t('components.sudoku.confirm_reset.title')),
        color: 'warning',
        buttonTrueText: String(this.$t('components.sudoku.speed_mode.confirm.ok')),
        buttonFalseText: String(this.$t('components.puzzles.actions.confirm.cancel')),
      })
      if (res) {
        this.setPurpose(String(this.$t('components.sudoku.confirm_reset.purpose')))
        this.showLoader()
        setTimeout(() => {
          this.initSudoku(this.sudoku)
          this.hideLoader()
        }, 667)
      }
    },
  },
}
</script>

<style lang="scss">
.sudoku {
  &__div {
    &--progress {
      max-width: 400px;
    }
  }
}
.pt {
  &-10-px {
    padding-top: 10px;
  }
}
#component-sudoku-card {
  width: calc(100vw - 48px);
  max-width: 400px;
  height: calc(100vw - 48px) !important;
  max-height: 400px !important;
  transform-style: preserve-3d;
}

#component-sudoku-field {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
